<template>
    <b-row>
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"></label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="6" sm="8" class="my-1">

        </b-col>
        <b-col md="4" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(wbs)="data">
                    <b-row>
                        <b-col md="5">
                            <small class="text-primary">Nama Balai/BPJ</small>
                            <h5 class="text-primary"><strong> {{ data.item.nama }}</strong></h5>
                            <h6 class=""> <small> Keterangan</small><br> <small style="text-align: justify;">{{
                                data.item.keterangan }}</small></h6>
                        </b-col>
                        <b-col md="7">
                            <b-row class="mb-1">
                                <b-col md="8">
                                    <small class="text-primary" v-show="data.item.listbalai != ''">Area Wilayah
                                        Kabupaten/Kota</small>
                                </b-col>
                                <b-col md="4">
                                    <b-badge block variant="info" size="sm" v-b-modal.modal-1
                                        @click="detailData(data.item)">
                                        <span><feather-icon icon="EditIcon" size="15"></feather-icon> Kelola
                                            Wilayah Balai/Bidang</span>
                                    </b-badge>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col md="4" v-for="kab in data.item.listbalai" v-bind:key="kab">
                                    <h6><small><strong> <b-badge variant="danger" size="sm"
                                                    @click="HapusData(kab.id_wilayah, data.item.id_bidang)"><feather-icon
                                                        icon="XIcon" size='15' /></b-badge> {{ kab.nm_wil
                                                        }}</strong></small></h6>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col md="4">

                                </b-col>
                                <b-col md="4">

                                </b-col>
                            </b-row>

                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
} from 'bootstrap-vue'

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    },
    props: {
        rs_data: {}
    },
    data() {
        return {
            perPage: 5,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [{
                key: 'wbs',
                label: '',
                thStyle: {
                    display: 'none'
                }
            }],


        }
    },
    computed: {
        items: {
            get: function () {
                return this.rs_data;
            },
            set: function (newValue) {
                return this.rs_data = newValue;
            }
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    emits: ['detailData', 'HapusData'],
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length
    },
    methods: {
        HapusData(id_wilayah, id_bidang) {
            if (confirm('Apakah anda yakin akan menghapus data ini...?')) {
                this.$emit('HapusData', id_wilayah, id_bidang)
            }
        },
        detailData(val) {
            this.$emit('detailData', val)
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>
<style>
.btn-block {
    display: block;
    width: 100%;
}
</style>